import { Navigate, useRoutes } from 'react-router-dom';
import MainPage from './pages/main';

export default function Router() {

    const routes = useRoutes([
        {
            path: '/',
            element: <MainPage />,
        },
        {
            path: '*',
            element: <Navigate to="/404" replace />,
        },
    ]);

    return routes;
}
