import { ApiScheme, ApiHost } from './const';

export async function ApiGet(path, callback, fail, ...args) {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'index/html',
        }
    }

    let url = `${ApiScheme}://${ApiHost}/api/v1${path}`;
    for (let i = 0; i < args.length; i += 1) {
        const arg = args[i]

        if (arg === undefined || arg === "") {
            return
        }

        url += `/${arg}`
    }

    fetch(url, options)
        .then(response => {
            if (response.status !== 200) {
                response.json()
                    .then(data => {
                        fail(data)
                    })
            } else {
                response.json()
                    .then(data => {
                        callback(data)
                    })
            }
        })
        .catch(err => fail(err));
}