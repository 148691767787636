import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Router from './routes';
import ReactGA from 'react-ga4';
import { GoogleAnalyticsID } from './utils/const';

// global variable
window.authToken = document.cookie.split('; ').filter(row => row.startsWith('authToken=')).map(c => c.split('=')[1])[0]
ReactGA.initialize(GoogleAnalyticsID, { debug: true });

export default function App() {

    return (
        <HelmetProvider>
            <BrowserRouter>
                <Router />
            </BrowserRouter>
        </HelmetProvider>
    );
}
