import { Helmet } from 'react-helmet-async';
import * as React from 'react';
import ReactGA from 'react-ga4';
import { Grid, Switch, Slider, Box, Button, Typography } from '@mui/material';
import { ApiGet } from '../utils/api';

export default function MainPage() {

    const [action, setAction] = React.useState(true);

    const [heat, setHeatValue] = React.useState(2);

    const [output, setOutput] = React.useState("");

    const handleGo = (event) => {
        const actionPath = (action ? "a" : "q");
        const path = "/random/" + heat + "/" + actionPath;

        ReactGA.event({
            category: "Event",
            action: "Click",
            label: "Do it",
        });

        ApiGet(
            path,
            data => {
                setOutput(data.data);
            },
            fail => {
            },
        );
    };

    return (
        <>
            <Helmet>
                <title> Main Page </title>
            </Helmet>

            <Grid container spacing={3} margin={3} >
                <Grid item xs>
                    Question or Action
                    <Switch
                        checked={action}
                        onChange={(_, value) => setAction(value)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Grid>

                <Grid item xs={9} />

                <Grid item xs={12} align="center">
                    <Button
                        variant="contained"
                        size="medium"
                        onClick={handleGo}
                    >
                        來吧
                    </Button>
                </Grid>

                <Grid item xs={12} align="center" />
                <Grid item xs={12} align="center" />
                <Grid item xs={12} align="center" />
                <Grid item xs={12} align="center" />
                <Grid item xs={12} align="center" />

                <Grid item xs={12} align="center">
                    <Typography variant="h4" color="#8a5082">
                        {output}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}
